var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-6"},[_vm._m(0),_c('v-select',{attrs:{"options":_vm.carteiraOpcoes,"multiple":"","label":"nome"},model:{value:(_vm.carteiras),callback:function ($$v) {_vm.carteiras=$$v},expression:"carteiras"}}),_c('hr')],1)]),_c('hr'),_c('b-table',{staticStyle:{"font-size":"10px"},attrs:{"items":_vm.extrato.lista,"small":"","fields":[
      { key: 'historico', label: 'Historico', sortable: true},
      { key: 'data', label: 'Data', sortable: true },
      { key: 'carteira', label: 'Carteira/Conta', sortable: true },
      { key: 'observacao', label: 'Obs.', sortable: true },
      { key: 'valor', label: 'Valor', class:'text-right', sortable: true },
    ]},scopedSlots:_vm._u([{key:"cell(data)",fn:function(row){return [_vm._v(" "+_vm._s(_vm._f("moment")(row.item.created_at,'DD/MM/YYYY HH:mm'))+" ")]}},{key:"cell(valor)",fn:function(row){return [_vm._v(" "+_vm._s(_vm._f("currency")(row.item.valor))+" ")]}}])})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',[_c('i',{staticClass:"fa fa-wallet mr-1"}),_vm._v(" Carteira / Conta")])
}]

export { render, staticRenderFns }