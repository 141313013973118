<template>
  <div>
    <div class="row">
      <div class="col-12 col-sm-6">
        <label><i class="fa fa-wallet mr-1" /> Carteira / Conta</label>
        <v-select
          :options="carteiraOpcoes"
          multiple
          v-model="carteiras"
          label="nome"
        />
        <hr />
      </div>
    </div>
    <hr />
 
    <b-table
      :items="extrato.lista"
      small
      style="font-size:10px"
      :fields="[
        { key: 'historico', label: 'Historico', sortable: true},
        { key: 'data', label: 'Data', sortable: true },
        { key: 'carteira', label: 'Carteira/Conta', sortable: true },
        { key: 'observacao', label: 'Obs.', sortable: true },
        { key: 'valor', label: 'Valor', class:'text-right', sortable: true },
      ]"
    >
    <template #cell(data)="row">
      {{row.item.created_at | moment('DD/MM/YYYY HH:mm')}}
    </template>
       <template #cell(valor)="row">
      {{row.item.valor | currency}}
    </template>
    </b-table>
  </div>
</template>

<script>
import BancoMovimentoLib from "../../../libs/BancoMovimentoLib";
import CarteirasLib from "../../../libs/Carteiras";
import moment from "moment";

export default {
  components: {},
  props: {
    data: Object,
    empresas: Array,
  },
  mounted() {
    this.carregarCarteiras();
    this.carregar();
  },
  data() {
    return {
      loading: false,
      extrato: null,
      carteiraOpcoes: [],
      carteiras: [],
    };
  },
  watch: {
    $props: {
      deep: true,
      handler() {
        this.carregar();
      },
    },
    carteiras: {
      deep: true,
      handler() {
        this.carregar();
      },
    },
  },
  computed: {},
  methods: {
    async carregarCarteiras() {
      this.carteiraOpcoes = (await CarteirasLib.getCareteiras()).data;
    },
    async carregar() {
      this.loading = true;
      this.extrato = {};
      try {
        let res = await BancoMovimentoLib.getExtrato({
          dt_i: moment(this.data.start).format("YYYY-MM-DD"),
          dt_f: moment(this.data.end).format("YYYY-MM-DD"),
          carteiras: this.carteiras.map((it) => it.id),
        });

        if (res && res.success) {
          this.extrato = res.data;
          if (this.extrato && this.extrato.lista) {
            
            this.extrato.lista = [
              {
                historico: "SALDO ANTERIOR",
                valor: this.extrato.saldo_anterior,
                _rowVariant:'secondary'
              },
              ...this.extrato.lista.map(it => ({ ...it, _rowVariant: it.tipo != 1 ? 'danger' : 'success' })),
               {
                historico: "SALDO FINAL",
                valor: this.extrato.saldo_final,
                _rowVariant:'warning'
              },
                {
                historico: "SALDO ATUAL",
                valor: this.extrato.saldo_atual,
                _rowVariant:'primary'
              },
            ];
          }
        }

        console.log("res", res);
      } catch (error) {
        console.log("ERROR ON GET EXTRATO", error);
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
